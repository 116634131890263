import {action, configure, makeObservable, observable} from 'mobx';
import {Inertia} from "@inertiajs/inertia";
import reachGoalYM from "@/Helpers/ReachGoalYM";
import reachGoalGA from "@/Helpers/ReachGoalGA";

/**
 * Класс для работы с избранным
 */
export default class ProfileStore {
    //Начальное состояние модалки
    @observable bonusesModal = false;
    @observable serverError = '';
    @observable serverOk = '';
    //Отзывы
    @observable reviews = [];
    reviewsCount = 5;
    @observable reviewsOneCount = 0;
    @observable reviewsTwoCount = 0;
    @observable reviewsThreeCount = 0;
    @observable reviewsFourCount = 0;
    @observable reviewsFiveCount = 0;
    //Модалка "Задать вопрос"
    @observable questionOpen = false;
    //Успешное добавление в лист ожидания
    @observable successAddWaitListOpen = false;
    @observable serverOkDeliveryAddress = '';
    @observable serverErrorDeliveryAddress = '';
    @observable serverTextScan = '';
    @observable serverErrorScan = '';
    @observable errorScan = '';
    //Принимаем ошибки сервера форма "Задать вопрос"
    @observable serverErrorSendRequest = '';
    //Определяем активный элемент в навигации
    @observable profileSelected = localStorage.getItem('profileSelected') ? Number(localStorage.getItem('profileSelected')) : 1;
    //Заголовок для H1
    @observable titleProfile = localStorage.getItem('titleProfile') ? localStorage.getItem('titleProfile') : 'Профиль';
    //Чек бокс - Напоминание о товарах в корзине
    @observable info_basket = true;
    //Чек бокс - Скидки, акции и новые коллекции
    @observable action_stocks_assortment = false;
    //Чек бокс - Push уведомления
    @observable push_notifications = true;
    //Статус - Лист ожидания
    @observable waitingServerTitle = '';
    @observable waitingServerMessage = '';
    @observable statusWaitingList = '';
    //Чек бокс Бонусная карта, принять участие, по умолчанию всегда стоит
    @observable checkedPersonal = true;
    //Успешная или не успешная отправка формы "Задать вопрос"
    @observable questionFlag = false;
    //Успешное или не успешное получение данных о заданных вопросах пользователя
    @observable resultAnswerFlag = false;
    //Дополнительный класс для формы во время отправки
    @observable formLoad = '';
    @observable formLoadPassportProfile = '';
    @observable formLoadWaitList = '';
    @observable formLoadDeliveryAddress = '';
    @observable formLoadScan = '';
    @observable formLoadResetPassword = '';
    @observable formLoadTakePartProgram = '';
    @observable serverResetPasswordOk = '';
    @observable serverPassportProfileOk = '';
    @observable serverResetPasswordError = '';
    @observable loadRepeatOrder = '';

    @observable files = [];
    @observable scanFlag = true;
    // Лоадеры
    @observable loaderAddWaitList = '';
    // Лист ожидания пользователя
    @observable usersWaitList = [];
    // Бонусная программа
    @observable dataDiscountCard = [];
    @observable bonusesModalInfo = [];
    @observable getDiscountCardLoader = false;
    @observable productsWithoutReviews = [];
    @observable ratingAVG = 0;
    @observable dataProvider = [];
    @observable result = [];
    @observable reviewsResult = [];


    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    /**
     * Открытие модалки "ХОТИТЕ БОЛЬШЕ БОНУСОВ?"
     */
    @action openModal() {
        this.bonusesModal = true;
    }

    /**
     * Закрытие модалки "ХОТИТЕ БОЛЬШЕ БОНУСОВ?"
     */
    @action closeModal() {
        this.bonusesModal = false;
    }

    /**
     * Навигация личный кабинет
     * @param key порядковый номер текущего выбранного элемента
     */
    @action onSelect = (key, homeStepsData = []) => {
        try {
            const item = homeStepsData.filter((el) => Number(el.id) === Number(key));
            if (item.length !== 0) {
                this.titleProfile = item[0].title;
                this.profileSelected = item[0].id;
            }

            localStorage.setItem('profileSelected', this.profileSelected);
            localStorage.setItem('titleProfile', this.titleProfile);
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Навигация личный кабинет, модалка
     * @param key
     */
    @action onClickNav = (key, homeStepsData) => {
        try {
            this.rootStore.rightMenuStore.closeRightMenu();
            this.onSelect(key, homeStepsData)
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Изменение чек бокса
     */
    @action checkedPersonalChange() {
        try {
            this.checkedPersonal = !this.checkedPersonal;
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Открытие модалки "Резюме"
     */
    @action openQuestionModal() {
        this.questionOpen = true;
    }

    /**
     * Закрытие модалки "Резюме"
     */
    @action closeQuestionModal() {
        this.questionOpen = false;
        this.questionFlag = false;
    }

    reviewsShow = (reviews) => {
        this.reviews = reviews;
        this.reviewsResult = reviews.slice(0, this.reviewsCount);
    }

    /**
     * Показать еще, отзывы в ЛК
     */
    @action showMoreReviewsProfile = () => {
        this.reviewsCount = this.reviewsCount + 3;
        this.reviewsResult = this.reviews.slice(0, this.reviewsCount);
    };

    @action getSizeWaitList = async (id, sizeSelect = false) => {
        this.loaderAddWaitList = 'load';

        const values = {
            id_product: id,
            size: '',
        };

        if (sizeSelect === false) {
            // Стучимся на сервак
            const response = await axios.post('/get_size_wait_list', {id: id});

            try {
                const {success} = response.data

                if (success.status) {
                    if (success.data.length === 0) {
                        await this.addWaitList(values);
                        // this.loaderAddWaitListNotSize = false;
                        this.loaderAddWaitList = '';
                    } else {
                        this.rootStore.productDetailStore.productData = success.data;
                        this.rootStore.productDetailStore.openModalNotSize(id);
                        this.loaderAddWaitList = '';
                    }
                } else {
                    this.loaderAddWaitList = '';
                }
            } catch (e) {
                console.log('e', e);
            }
        } else {
            values.size = sizeSelect;
            await this.addWaitList(values);
            // this.loaderAddWaitListNotSize = false;
            this.loaderAddWaitList = '';
        }
    }

    /**
     * Добавляем в лист ожидания
     * @param values
     * @returns {Promise<void>}
     */
    @action addWaitList = async (values) => {
        try {
            this.formLoadWaitList = 'load';
            const response = await axios.post('/add_wait_list', values);

            const {success} = response.data;
            const {status = false, data = [], errors = []} = success;

            if (status) {
                this.waitingServerTitle = data.title;
                this.waitingServerMessage = data.message;
            } else {
                this.waitingServerTitle = errors.title;
                this.waitingServerMessage = errors.errorMessage[0];
            }

            this.formLoadWaitList = '';
            this.openSuccessAddWaitList();
        } catch (e) {
            this.formLoadWaitList = '';
            this.waitingServerTitle = 'Внимание';
            this.waitingServerMessage = 'Не удалось добавить украшение в лист ожидания!';
            this.openSuccessAddWaitList();
        }
    };

    /**
     * Открытие модалки "Успешное добавление в лист ожидания"
     */
    @action openSuccessAddWaitList() {
        this.rootStore.productDetailStore.closeModalNotSize()
        this.successAddWaitListOpen = true;
    }

    /**
     * Закрытие модалки "Успешное добавление в лист ожидания"
     */
    @action closeSuccessAddWaitList() {
        this.waitingServerTitle = '';
        this.waitingServerMessage = '';
        this.successAddWaitListOpen = false;
    }

    /**
     * Удаление ждуна
     * @param id
     * @param size
     */
    @action deleteItemWaitList = async (id, size) => {
        let values = {
            id: id,
            size: size,
        };

        // Стучимся на сервак
        const response = await axios.post('/delete_item_wait_list', values);

        try {
            const {success} = response.data;
            const {status = false} = success;

            if (status) {
                Inertia.reload();
            }
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Обработчик файлов
     * @param files
     */
    @action onFilesChange = (files) => {
        this.serverTextScan = '';
        this.files = files;
    }

    /**
     * Обработчик ошибок
     * @param error
     * @param file
     */
    @action onFilesError = (error, file) => {
        if (error.code === 1) {
            this.errorScan = 'Загружать можно только изображения!';
        } else if (error.code === 2) {
            this.errorScan = 'Файл слишком большой!';
        } else if (error.code === 3) {
            this.errorScan = 'Файл слишком мал!';
        } else if (error.code === 4) {
            this.errorScan = 'Достигнуто максимальное количество файлов!';
        }
    }

    /**
     * Отправка файла (ов) на сервер
     */
    @action filesUpload = async () => {
        this.formLoadScan = 'load';
        if (this.files.length === 0) {
            this.serverTextScan = 'Пожалуйста, выберите изображение!';
            this.formLoadScan = '';
            return false;
        }

        const formData = new FormData();
        Object.keys(this.files).forEach((key) => {
            const file = this.files[key];
            formData.append(key, new Blob([file], {type: file.type}), file.name || 'file');
        })

        // Стучимся на сервак
        const response = await axios.post('/scanUpload', formData);

        try {
            const {success} = response.data;
            const {status = false, data = [], errors = []} = success;

            if (status) {
                this.scanFlag = false;
                this.formLoadScan = '';
                this.files = [];
                this.serverTextScan = data.message;
            } else {
                this.formLoadScan = '';
                this.serverErrorScan = errors.errorMessage[0];
            }
            this.formLoadScan = '';
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Повтор заказа
     * @param id
     * @returns {boolean}
     */
    repeatOrder = (id = null) => {
        try {
            Inertia.post(route('repeatOrder'), {id: id}, {
                onSuccess: (page) => {
                    this.loadRepeatOrder = '';
                    this.rootStore.modalStore.successRepeatOrderServerMsg = page.props.success.message;
                    this.rootStore.modalStore.successRepeatOrderToggle = true;
                },
                onError: (errors) => {
                    this.loadRepeatOrder = '';
                },
            });
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Отмена заказа пользователем
     * @param id
     */
    cancelOrder = (id) => {
        try {
            Inertia.post(route('cancelOrder'), {id: id}, {
                onSuccess: (page) => {
                    reachGoalYM('account_cancel_order');
                    reachGoalGA('account_cancel_order');
                },
                onError: (errors) => {
                    if (errors?.errorMessage) {
                        this.rootStore.basketStore.errorOrderMessage = errors.errorMessage;
                        this.rootStore.basketStore.openDialogBasket();
                    }
                },
            });
        } catch (e) {
            console.log('e', e);
        }
    }

    // /**
    //  * Удаляем соцсети связанные с профилем сайта
    //  * @param provider
    //  * @returns {boolean}
    //  */
    // deleteLinkedAccounts = async (provider = null) => {
    //     if (provider === null) return false;
    //     const values = {
    //         provider: provider
    //     }
    //
    //     // Стучимся на сервак
    //     const response = await axios.post('/deleteLinkedAccounts', values);
    //
    //     try {
    //         const {success} = response.data
    //
    //         if (success.status) {
    //             this.deliveryAddressChange(success.user)
    //         }
    //     } catch (e) {
    //         console.log('e', e);
    //     }
    // }

    deliveryAddressChange = (user) => {
        let social_account = user.social_account || [];
        const staticSocial = [
            'yandex',
            'google',
            'vkontakte'
        ];

        if (social_account.length !== 0) {
            this.dataProvider = Array.from(social_account, ({provider}) => provider);
        } else {
            this.dataProvider = []
        }

        this.result = staticSocial.filter(el => !this.dataProvider.includes(el));
    }
}
