/**
 * Для рекламной платформы VK ads
 * @param goal цель string (viewProduct, addToWishlist, addToCart, purchase)
 * @param price стоимость товара string
 * @param productId идентификатор товара (ов) string or array (в том виде, как он присутствует в вашем фиде)
 */
const reachGoalVkAds = (goal, price, productId) => {
    try {
        let attempts = 0;
        const maxAttempts = 60;

        const intervalId = setInterval(() => {
            attempts++;

            if (typeof _tmr === "object") {
                // console.log('Success: _tmr is available', _tmr);

                // Вызываем функцию _tmr.push для отправки события
                _tmr.push({
                    type: 'reachGoal',
                    id: 3606599,
                    value: price,
                    goal: goal,
                    params: {product_id: productId}
                });

                // Останавливаем интервал сразу после успешного пуша
                clearInterval(intervalId);
            }

            if (attempts >= maxAttempts) {
                console.log('Max attempts reached, _tmr is still not available');
                clearInterval(intervalId);
            }
        }, 100);
    } catch (e) {
        console.log('Error:', e);
    }
};

// Примеры из ТЗ
const TZ = () => {
    // Просмотр карточки товара:
    _tmr.push({
        type: 'reachGoal',
        id: 3606599,
        value: "VALUE",
        goal: "viewProduct",
        params: {product_id: "PRODUCT_ID"}
    });

    // Добавление товара в избранное:
    _tmr.push({
        type: 'reachGoal',
        id: 3606599,
        value: "VALUE",
        goal: "addToWishlist",
        params: {product_id: "PRODUCT_ID"}
    });

    // Добавление товара в корзину:
    _tmr.push({
        type: 'reachGoal',
        id: 3606599,
        value: "VALUE",
        goal: "addToCart",
        params: {product_id: "PRODUCT_ID"}
    });

    // Завершение покупки:
    _tmr.push({
        type: 'reachGoal',
        id: 3606599,
        value: "VALUE",
        goal: "purchase",
        params: {product_id: "PRODUCT_ID"}
    });

    // Для передачи нескольких товаров используйте следующий JavaScript-код:
    var _tmr = window._tmr || (window._tmr = []);
    _tmr.push({
        type: "reachGoal",
        id: 3606599,
        value: "VALUE",
        goal: "purchase",
        params: {
            product_id: ['PRODUCT_ID_1', 'PRODUCT_ID_2', 'PRODUCT_ID_N']
        }
    });
};

export default reachGoalVkAds;
